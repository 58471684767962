export default {
  业务标准化: '業務標準化',
  '规范业务流程，提高合作效率': '規範業務流程，提高合作效率',
  产品信息化: '產品信息化',
  '产品规范管理，信息快捷更新': '產品規範管理，信息快捷更新',
  交易线上化: '交易線上化',
  '打破时空限制，实时在线确认': '打破時空限制，實時在線確認',
  管理数智化: '管理數智化',
  '数据辅助分析，智能服务业务': '數據輔助分析，智能服務業務',
  迪士尼: '迪士尼',
  長隆門票: '長隆門票',
  門票: '門券',
  長隆套票: '長隆套票',
  套票: '套票',
  敬請期待: '敬請期待',
}
