export default {
  login: '登    入',
  account: '帳號',
  password: '密碼',
  rules: {
    username: '請輸入帳號',
    password: '請輸入密碼',
  },
  accountPasswordLogin: '帳號密碼登入',
}
